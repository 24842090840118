<template>
  <v-container fluid class="px-2 custom-container-details">
    <div style="max-width: 1280px;">
      <v-row wrap no-gutters class="pb-8">
        <v-col
          cols="12"
        >
          <v-card v-if="instance && instance.threadsStats" class="pa-5 custom-bg rounded-lg">
            <v-card-title class="d-flex align-center position-relative">
              <div class="d-flex align-center justify-space-between instance-details-prop">
                <div class="d-flex align-center justify-space-between flex-grow-1">
                  <div>{{ $lang.labels.name }}: &nbsp;</div>
                  <div class="d-flex align-center justify-start justify-self-end instance-details-prop-val">
                    <span class="color-primary">{{ instance.name }}</span>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="primary"
                          text
                          large
                          v-bind="attrs"
                          v-on="on"
                          @click="clipboardCopy(instance.name)"
                        >
                          <v-icon>mdi-vector-combine</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $lang.labels.copy }}</span>
                    </v-tooltip>
                  </div>
                </div>
                <div class="d-flex align-center justify-start instance-refresh-button">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        text
                        large
                        v-bind="attrs"
                        v-on="on"
                        @click="fetchData(instance.id)"
                      >
                        <v-icon>mdi-refresh</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $lang.labels.reloadData }}</span>
                  </v-tooltip>
                </div>
              </div>
            </v-card-title>
            <v-card-title>
              <div class="d-flex align-center justify-space-between instance-details-prop">
                <div class="d-flex">{{ $lang.labels.type }}: &nbsp;</div>
                <div class="d-flex align-center justify-start instance-details-prop-val">
                  <span class="color-primary">{{ $lang.status[instance.type] }}</span>
                </div>
              </div>
            </v-card-title>
            <v-card-title>
              <div class="d-flex align-center justify-space-between instance-details-prop">
                <div class="d-flex">{{ $lang.labels.created }}: &nbsp;</div>
                <div class="d-flex align-center justify-start instance-details-prop-val">
                  <span class="color-primary">{{ instance.createdOn | formatDateTimePrecise }}</span>
                </div>
              </div>
            </v-card-title>
            <v-card-title>
              <div class="d-flex align-center justify-space-between instance-details-prop">
                <div class="d-flex">{{ $lang.labels.lastUpdateHeartbeat }}: &nbsp;</div>
                <div class="d-flex align-center justify-start instance-details-prop-val">
                  <span class="color-primary">{{ instance.heartbeat | formatDateTimePrecise }}</span>
                </div>
              </div>
            </v-card-title>
            <v-card-title>
              <div class="d-flex justify-space-between instance-details-prop">
                <div class="d-flex">{{ $lang.labels.ipAddresses }}: &nbsp;</div>
                <div v-if="instance.ipAddress" class="d-flex flex-column align-start instance-details-prop-val">
                  <template v-for="ip in instance.ipAddress.split(',')">
                    <div :key="ip" class="d-flex align-center">
                      <p class="color-primary p-0 m-0" style="margin-bottom: 0">{{ ip }}</p>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="primary"
                            text
                            large
                            v-bind="attrs"
                            v-on="on"
                            @click="clipboardCopy(ip)"
                          >
                            <v-icon>mdi-vector-combine</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $lang.labels.copy }}</span>
                      </v-tooltip>
                    </div>
                  </template>
                </div>
                <div v-else class="d-flex flex-column align-start instance-details-prop-val">
                  <p class="color-primary p-0 m-0" style="margin-bottom: 0">{{ $lang.labels.none }}</p>
                </div>
              </div>
            </v-card-title>
            <v-card-title>
              <div class="d-flex align-center justify-space-between instance-details-prop">
                <div class="d-flex">{{ $lang.labels.hazelcastMember }}: &nbsp;</div>
                <div class="d-flex align-center justify-start instance-details-prop-val">
                  <span class="color-primary">{{ instance.hazelcastMember || $lang.labels.no }}</span>
                </div>
              </div>
            </v-card-title>
            <v-card-title class="pb-0 pt-4">
              <span>{{ $lang.labels.threadStatistics }}</span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="primary"
                    text
                    large
                    v-bind="attrs"
                    v-on="on"
                    @click="clipboardCopy(JSON.stringify(instance.threadsStats))"
                  >
                    <v-icon>mdi-vector-combine</v-icon>
                  </v-btn>
                </template>
                <span>{{ $lang.labels.copy }}</span>
              </v-tooltip>
            </v-card-title>
            <v-row no-gutters>
              <v-col cols="12">
                <v-row no-gutters class="pt-3">
                  <v-col v-if="instance.threadsStats.http" cols="3">
                    <h3 class="mb-2">{{ $lang.labels.http }}</h3>
                    <p>{{ $lang.labels.available }}: <strong>{{ instance.threadsStats.http.available }}</strong></p>
                    <p>{{ $lang.labels.used }}: <strong>{{ instance.threadsStats.http.used }}</strong></p>
                  </v-col>
                  <v-col v-if="instance.threadsStats.internalJobs" cols="3">
                    <h3 class="mb-2">{{ $lang.labels.internalJobs }}</h3>
                    <p>{{ $lang.labels.available }}: <strong>{{ instance.threadsStats.internalJobs.available }}</strong></p>
                    <p>{{ $lang.labels.used }}: <strong>{{ instance.threadsStats.internalJobs.used }}</strong></p>
                  </v-col>
                  <v-col v-if="instance.threadsStats.processing" cols="3">
                    <h3 class="mb-2">{{ $lang.labels.processing }}</h3>
                    <p>{{ $lang.labels.available }}: <strong>{{ instance.threadsStats.processing.available }}</strong></p>
                    <p>{{ $lang.labels.used }}: <strong>{{ instance.threadsStats.processing.used }}</strong></p>
                  </v-col>
                  <v-col v-if="instance.threadsStats.emails" cols="3">
                    <h3 class="mb-2">{{ $lang.labels.emails }}</h3>
                    <p>{{ $lang.labels.available }}: <strong>{{ instance.threadsStats.emails.available }}</strong></p>
                    <p>{{ $lang.labels.used }}: <strong>{{ instance.threadsStats.emails.used }}</strong></p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-card-title class="pb-0">
              <span>{{ $lang.labels.memory }}</span>
            </v-card-title>
            <v-row no-gutters>
              <v-col cols="12">
                <v-row no-gutters justify="space-between" class="mt-2">
                  <p class="p-0 m-0" style="margin-bottom: 0">0</p>
                  <p class="p-0 m-0" style="margin-bottom: 0"><span class="font-weight-bold color-error">{{ usedMemoryMB }}</span> MB Used / <span class="font-weight-bold color-success">{{ availableMemoryMB }}</span> MB Available</p>
                  <p class="p-0 m-0" style="margin-bottom: 0">Total <span class="font-weight-bold color-primary">{{ totalMemoryMB }}</span> MB</p>
                </v-row>
                <v-row no-gutters justify="space-between" class="mb-4">
                  <div :style="`width: 100%; height: 60px; background-color: ${$vuetify.theme.currentTheme.success}; position: relative;`">
                    <div :style="usedMemoryStyle">
                      <span style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); color: white;">
                      </span>
                    </div>
                  </div>
                </v-row>
              </v-col>
            </v-row>
            <v-card-title class="pb-0">
              <span>{{ $lang.labels.cpu }}</span>
            </v-card-title>
            <v-row no-gutters>
              <v-col cols="12">
                <v-row no-gutters justify="space-between" class="mt-2">
                  <p class="p-0 m-0" style="margin-bottom: 0">0</p>
                  <p class="p-0 m-0" style="margin-bottom: 0"><span class="font-weight-bold color-primary">{{ instance.systemCpuLoad }}%</span> Load</p>
                  <p class="p-0 m-0" style="margin-bottom: 0">100</p>
                </v-row>
                <v-row no-gutters justify="space-between" class="mb-4">
                  <div style="width: 100%; height: 60px; background-color: lightgrey; position: relative;">
                    <div :style="cpuLoadStyle">
                    </div>
                  </div>
                </v-row>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="12"
              >
                <v-btn
                  outlined
                  color="primary"
                  :to="{ name: 'logs-instances', params: { lang: $lang.current_lang } }"
                >
                  <v-icon
                    right
                    dark
                    class="ma-0"
                  >
                    mdi-arrow-left
                  </v-icon>

                  <span class="ml-1">{{ $lang.actions.back }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import {
  getInstanceByIdUsingGET as getInstance
} from '@/utils/api'

export default {
  data() {
    return {
      loading: false,
      lock: false,
      instance: {
        id: 0,
        name: '',
        type: '',
        heartbeat: '',
        createdOn: '',
        availableMemory: 0,
        totalMemory: 0,
        systemCpuLoad: 0,
        threadsStats: null,
        ipAddress: ''
      }
    }
  },
  computed: {
    usedMemoryMB() {
      return this.bytesToMB(this.instance.totalMemory - this.instance.availableMemory)
    },
    availableMemoryMB() {
      return this.bytesToMB(this.instance.availableMemory)
    },
    totalMemoryMB() {
      return this.bytesToMB(this.instance.totalMemory)
    },
    usedMemoryStyle() {
      const width = (this.usedMemoryMB / this.totalMemoryMB) * 100

      return {
        width: width + '%',
        height: '60px',
        backgroundColor: this.$vuetify.theme.currentTheme.error, // Color for used memory
        position: 'relative',
        borderRadius: '0 12px 12px 0'
      }
    },
    cpuLoadStyle() {
      return {
        width: this.instance.systemCpuLoad + '%',
        height: '60px',
        backgroundColor: this.instance.systemCpuLoad > 80 ? this.$vuetify.theme.currentTheme.error : this.instance.systemCpuLoad > 50 ? this.$vuetify.theme.currentTheme.warning : this.$vuetify.theme.currentTheme.info, // Color for CPU load
        position: 'relative',
        borderRadius: '0 12px 12px 0'
      }
    }
  },
  watch: {
  },
  created() {
    if (this.$route.params.id) {
      this.fetchData(this.$route.params.id)
    }
  },
  methods: {
    bytesToMB(bytes) {
      return Math.round(bytes / 1048576) // 1 MB = 1048576 bytes
    },
    fetchData(id) {
      this.loading = true

      getInstance({ id })
        .then((res) => {
          this.instance = res.data.data
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
        .finally(() => {
          this.loading = false
        })
    },
    clipboardCopy(type) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard

      clipboardData.writeText(type)

      this.addSnackbar({
        message: this.$lang.success.copiedClipboard,
        timeout: 5000,
        color: 'success'
      })
    }
  }
}
</script>
<style lang="scss">
.instance-details-prop {
  width: 100%;
  align-self: end;
  font-size: 16px;

  &-val {
    width: 60%;
  }
}

.instance-refresh-button {
  position: absolute;
  right: 0;
}
</style>
